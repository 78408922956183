import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CgtvDashboardComponent } from './dashboard/cgtv-dashboard.component';
import { DashboardFiltersComponent } from './dashboard/dashboard-filters/dashboard-filters.component';
import { DashboardAccordionComponent } from './dashboard/dashboard-accordion/dashboard-accordion.component';
import { CompanyDetailsTabComponent } from './dashboard/dashboard-accordion/company-details-tab/company-details-tab.component';
import { ApportionmentsTabComponent } from './dashboard/dashboard-accordion/apportionments-tab/apportionments-tab.component';
import { CorporateActionsTabComponent } from './dashboard/dashboard-accordion/corporate-actions-tab/corporate-actions-tab.component';
import { CgtvNavbarComponent } from './-components-/navbar/cgtv-navbar.component';
import { CorporateActionDetailsComponent } from './-components-/corporate-action-details/corporate-action-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { SplitButtonModule } from 'primeng/splitbutton';
import { MegaMenuModule } from 'primeng/megamenu';
import { TabMenuModule } from 'primeng/tabmenu';
import { AccordionModule } from 'primeng/accordion';
import { InputTextModule } from 'primeng/inputtext';
import { DocumentLinkComponent } from './-components-/corporate-action-details/common-components/document-link/document-link.component';
import { ParagraphComponent } from './-components-/corporate-action-details/common-components/paragraph/paragraph.component';
import { ComponentsTableComponent } from './-components-/corporate-action-details/common-components/components-table/components-table.component';
import { BlueLabelComponent } from './-components-/corporate-action-details/common-components/blue-label/blue-label.component';
import { RedLabelComponent } from './-components-/corporate-action-details/common-components/red-label/red-label.component';
import { NoteSectionComponent } from './-components-/corporate-action-details/common-components/note-section/note-section.component';
import { DocumentListComponent } from './-components-/corporate-action-details/common-components/document-list/document-list.component';
import { SmallHeaderComponent } from './-components-/corporate-action-details/common-components/small-header/small-header.component';
import { CommonDisclaimerComponent } from './-components-/common-disclaimer/common-disclaimer.component';
import { CalendarModule } from 'primeng/calendar';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { SelectButtonModule } from 'primeng/selectbutton';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TabViewModule } from 'primeng/tabview';
import { ChipModule } from 'primeng/chip';
import { ApportionmentEntryComponent } from './dashboard/dashboard-accordion/apportionments-tab/apportionment-entry/apportionment-entry.component';
import { DividendCalculatorComponent } from './dividend-calculator/dividend-calculator.component';
import { DividendCalculatorFiltersComponent } from './dividend-calculator/-components-/filters/dividend-calculator-filters.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { DividendCalculatorTableContainerComponent } from './dividend-calculator/-components-/table-container/dividend-calculator-table-container.component';
import { DividendCalculatorTableComponent } from './dividend-calculator/-components-/table-container/-components-/table/dividend-calculator-table.component';
import { DividendCalculatorIndividualEntryUnitsInputComponent } from './dividend-calculator/-components-/table-container/-components-/individual-entry-units-input/dividend-calculator-individual-entry-units-input.component';
import { DescriptionHTMLRendererComponent } from './-components-/description-htmlrenderer/description-htmlrenderer.component';
import { CoopActionPopupComponent } from './dashboard/coop-action-popup/coop-action-popup.component';
import { DialogModule } from 'primeng/dialog';
import { FieldDirective } from './-components-/corporate-action-details/field.directive';
import { DividendDetailsRendererComponent } from './-components-/corporate-action-details/concrete-types/dividend-details-renderer/dividend-details-renderer.component';
import { BuybackDetailsRendererComponent } from './-components-/corporate-action-details/concrete-types/buyback-details-renderer/buyback-details-renderer.component';
import { DemergerDetailsRendererComponent } from './-components-/corporate-action-details/concrete-types/demerger-details-renderer/demerger-details-renderer.component';
import { MergeDetailsRendererComponent } from './-components-/corporate-action-details/concrete-types/merge-details-renderer/merge-details-renderer.component';
import { BonusDetailsRendererComponent } from './-components-/corporate-action-details/concrete-types/bonus-details-renderer/bonus-details-renderer.component';
import { SPPDetailsRendererComponent } from './-components-/corporate-action-details/concrete-types/sppdetails-renderer/sppdetails-renderer.component';
import { OptionExpiryDetailsRendererComponent } from './-components-/corporate-action-details/concrete-types/option-expiry-details-renderer/option-expiry-details-renderer.component';
import { RightsIssueDetailsRendererComponent } from './-components-/corporate-action-details/concrete-types/rights-issue-details-renderer/rights-issue-details-renderer.component';
import { UnknownTypeDetailsRendererComponent } from './-components-/corporate-action-details/concrete-types/unknown-type-details-renderer/unknown-type-details-renderer.component';
import { DelistingDetailsRendererComponent } from './-components-/corporate-action-details/concrete-types/delisting-details-renderer/delisting-details-renderer.component';
import { ActivityTypeHeaderComponent } from './-components-/corporate-action-details/common-components/activity-type-header/activity-type-header.component';
import { EstimateColourBoxComponent } from './dashboard/dashboard-accordion/apportionments-tab/apportionment-entry/estimate-colour-box/estimate-colour-box.component';
import { CommonSharedModule } from '../common/shared.module';
import { ReleaseNotesComponent } from './release-notes/release-notes.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InfoDetailsRendererComponent } from './-components-/corporate-action-details/concrete-types/info-details-renderer/info-details-renderer.component';
import { ReconstructionDetailsRendererComponent } from './-components-/corporate-action-details/concrete-types/reconstruction-details-renderer/reconstruction-details-renderer.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SchemaArrangementRendererComponent } from './-components-/corporate-action-details/concrete-types/schema-arrangement-renderer/schema-arrangement-renderer.component';
import { TrustSecurityComponent } from './trust-components/trust-components.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { MultiSelectModule } from 'primeng/multiselect';

@NgModule({
  declarations: [
    CgtvDashboardComponent,
    DashboardFiltersComponent,
    DashboardAccordionComponent,
    CompanyDetailsTabComponent,
    ApportionmentsTabComponent,
    CorporateActionsTabComponent,
    CgtvNavbarComponent,
    CorporateActionDetailsComponent,
    DocumentLinkComponent,
    ParagraphComponent,
    ComponentsTableComponent,
    BlueLabelComponent,
    RedLabelComponent,
    NoteSectionComponent,
    DocumentListComponent,
    SmallHeaderComponent,
    CommonDisclaimerComponent,
    ApportionmentEntryComponent,
    DividendCalculatorComponent,
    DividendCalculatorFiltersComponent,
    DividendCalculatorTableContainerComponent,
    DividendCalculatorTableComponent,
    DividendCalculatorIndividualEntryUnitsInputComponent,
    DescriptionHTMLRendererComponent,
    CoopActionPopupComponent,
    FieldDirective,
    DividendDetailsRendererComponent,
    BuybackDetailsRendererComponent,
    DemergerDetailsRendererComponent,
    MergeDetailsRendererComponent,
    BonusDetailsRendererComponent,
    SPPDetailsRendererComponent,
    OptionExpiryDetailsRendererComponent,
    RightsIssueDetailsRendererComponent,
    UnknownTypeDetailsRendererComponent,
    InfoDetailsRendererComponent,
    ReconstructionDetailsRendererComponent,
    DelistingDetailsRendererComponent,
    SchemaArrangementRendererComponent,
    ActivityTypeHeaderComponent,
    EstimateColourBoxComponent,
    ReleaseNotesComponent,
    TrustSecurityComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    DropdownModule,
    TableModule,
    CheckboxModule,
    BreadcrumbModule,
    SplitButtonModule,
    MegaMenuModule,
    TabMenuModule,
    AccordionModule,
    CalendarModule,
    ButtonModule,
    RippleModule,
    SelectButtonModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    TabViewModule,
    InputNumberModule,
    DialogModule,
    RadioButtonModule,
    CommonSharedModule,
    ProgressSpinnerModule,
    ChipModule,
    ProgressBarModule,
    MultiSelectModule
  ],
  exports: [CommonDisclaimerComponent, DescriptionHTMLRendererComponent]
})
export class CgtViewModule {}
