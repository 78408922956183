import { Component, OnInit } from '@angular/core';
import { SwalError } from '../../../common/util/swal-mixins/swal-error';
import { LegacyDirectApiRequest } from '../../../-services-/auth-framework/api-connect/legacy-direct-api-request';
import { Roles } from '../../../-services-/auth-framework/api-constants/roles/roles';
import {
  AdministratorRole,
  AdvisorRole,
  BackofficeAdminRole,
  BackofficeRole,
  CompanyManagerRole,
  SiteAdminRole,
  eSupportRole
} from '../../../-services-/auth-framework/api-constants/roles/role-instances';
import { SwalSuccess } from '../../../common/util/swal-mixins/swal-success';
import { Router } from '@angular/router';
import { ApiHttpRequest } from '../../../../api-connector/api-http-request';
import { extractErrorText } from '../../../utilities/extract-error-text';
import { AuthProviderService } from 'src/app/-services-/auth-framework/auth-provider.service';

@Component({
  selector: 'app-create-user-page',
  templateUrl: './create-user-page.component.html',
  styleUrls: ['./create-user-page.component.css']
})
export class CreateUserPageComponent implements OnInit {
  roleChoices: Roles[] = [];

  // view states
  isLoading = true;
  currentUserCount = 0;
  maximumUserCount = 0;
  isSSO = false;
  isSSOEnabled = false;

  // input state models
  userEmail = '';
  selectedRole: number = CompanyManagerRole.id;
  adminSelected = false;
  isMFAEnforced = true;

  constructor(private router: Router, private authService: AuthProviderService) {}

  ngOnInit(): void {
    this.loadAllowableUserCount();
    this.getRoles();
  }

  get isEsupportUser() {
    return [eSupportRole].some((role) => this.authService.verifyUserRole(role));
  }

  get isSiteAdminUser() {
    return [SiteAdminRole].some((role) => this.authService.verifyUserRole(role));
  }

  get isAdministrator() {
    return [AdministratorRole].some((role) => this.authService.verifyUserRole(role));
  }

  get canBeAdmin() {
    return Number(this.selectedRole) === CompanyManagerRole.id;
  }

  get noticeMsg() {
    return `Your organisation is allowed to create unlimited users but only ${this.getMaxUserCountPhrase()}.`;
  }

  getRoles = () => {
    if (this.isEsupportUser) {
      this.roleChoices = [SiteAdminRole];
      this.selectedRole = SiteAdminRole.id;
    } else if (this.isSiteAdminUser || this.isAdministrator) {
      this.roleChoices = [CompanyManagerRole, BackofficeAdminRole, BackofficeRole, AdvisorRole];
      this.selectedRole = CompanyManagerRole.id;
    }
  };

  async loadAllowableUserCount() {
    try {
      const d = await ApiHttpRequest.getFullCustomerOrganisationInformation();
      this.currentUserCount = d.users.length;
      this.maximumUserCount = d.allowedUsersNumber;
      this.isSSOEnabled = d.isSSOEnabled;
    } catch (e) {
      await SwalError.fire('Failed to load current customer organisation information');
    } finally {
      this.isLoading = false;
    }
  }

  async submitForm() {
    try {
      this.isLoading = true;
      this.assertValidity();

      const userRoleIds = [this.selectedRole];
      if (this.canBeAdmin && this.adminSelected) {
        userRoleIds.push(AdministratorRole.id);
      }

      await LegacyDirectApiRequest.post('/api/users/add', {
        userEmail: this.userEmail,
        isMFAEnforced: this.isMFAEnforced,
        userRoleIds,
        isSSO: this.isSSO
      });

      await SwalSuccess.fire('The user has been added');
      await this.router.navigate(['users']);
    } catch (e: unknown) {
      await SwalError.fire({ text: extractErrorText(e) });
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * Checks that the form is valid,
   * otherwise throw an exception
   * @private
   */
  private assertValidity() {
    // 1. userEmail must not be empty
    if (this.userEmail === '') {
      throw new Error('User email must not be empty');
    }

    // 2. check maximum user count
    // if (this.currentUserCount >= this.maximumUserCount) {
    //   throw new Error('Your organisation is not allowed to create any more users.');
    // }
  }

  /**
   * e.g. 1 user, 10 users, or 39 users.
   */
  getMaxUserCountPhrase() {
    const singular = this.maximumUserCount == 1;
    return `${this.maximumUserCount} ${singular ? 'active user' : 'active users'}`;
  }
}
