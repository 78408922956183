<div class="p-card m-3 p-3">
  <div class="inner-form">
    <div class="col-12">
      <h1 class="m-0">Create user</h1>
      <!-- <p *ngIf="currentUserCount > 0"></p> -->
      <p-message [style]="{ 'margin-top': '1rem' }" *ngIf="currentUserCount > 0" severity="info" [text]="noticeMsg"></p-message>
    </div>

    <div class="col-12 p-fluid">
      <span class="p-float-label">
        <input type="text" pInputText [(ngModel)]="userEmail" />
        <label>User's email address</label>
      </span>
    </div>

    <div class="col-12 p-fluid">
      <span class="p-float-label">
        <p-dropdown optionLabel="displayName" optionValue="id" [options]="roleChoices" [(ngModel)]="selectedRole"></p-dropdown>
        <label>Role</label>
      </span>
    </div>

    <div class="col-12 p-fluid" *ngIf="canBeAdmin">
      <p-checkbox label="Grant Administrator Privileges" [binary]="true" [(ngModel)]="adminSelected"></p-checkbox>
    </div>
    <div class="col-12 p-fluid">
      <p-checkbox label="Single Sign-On" [binary]="true" [(ngModel)]="isSSO" [disabled]="!isSSOEnabled"></p-checkbox>
    </div>
    <!-- <div class="col-12 p-fluid">
      <p-checkbox label="Enforce MFA" [binary]="true" [(ngModel)]="isMFAEnforced"></p-checkbox>
    </div> -->

    <div class="col-12">
      <p-button
        label="Confirm"
        styleClass="p-button-outlined"
        (onClick)="submitForm()"
        [icon]="isLoading ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
        [disabled]="isLoading"
      ></p-button>
    </div>
  </div>
</div>
