import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  BaseReportType,
  CostChangesParams,
  IBaseReportParams,
  ISecurity,
  IncomeParams,
  RealisedParams,
  ReportCategory,
  ReportParams,
  ReportRequestBody,
  UnRealisedParams,
  incomeExtractMap
} from './reports.types';
import { ISubPortfolio } from '../dashboard/sub-portfolios/sub-portfolios.component.types';

export interface GenerateReportParams {
  url: string;
  method?: string;
  body?: ReportRequestBody;
  params?: Record<string, any>;
}

@Injectable()
export class ReportsService {
  constructor(private http: HttpClient) {}

  getAllPortfolios = () => {
    return this.http.get(`api/portfolios/all`);
  };

  getAllSubPortfolios = (portfolioId: number) => {
    return this.http.get<ISubPortfolio[]>(`api/sub-portfolios/all?portfolioId=${portfolioId}`);
  };

  _generateReport = (req: GenerateReportParams) => {
    const { url, body, params } = req;
    const queryParams = params ? new URLSearchParams(JSON.parse(JSON.stringify(params))) : '';
    const reqUrl = queryParams ? `${url}?${queryParams}` : url;
    return this.http.post(reqUrl, body);
  };

  _generateBaseReport = (req: GenerateReportParams) => {
    const { url, method, params } = req;
    const queryParams = params ? new URLSearchParams(JSON.parse(JSON.stringify(params))) : '';
    const reqUrl = queryParams ? `${url}?${queryParams}` : url;
    if (method) {
      const isGet = method === 'get';
      const data = {
        params: isGet ? params : undefined,
        body: !isGet ? params : undefined
      };
      return this.http.request(method, url, { ...data, observe: 'response', responseType: 'blob' });
      // return this.http.post(url, body, { observe: 'response', responseType: 'blob' });
    } else {
      return this.http.get(reqUrl, { observe: 'response', responseType: 'blob' });
    }
  };

  generateReport = (type: string, portfolioId: number, params: ReportParams) => {
    const queryParams = new URLSearchParams(JSON.parse(JSON.stringify(params)));
    return this.http.get(`api/old-reports/${type}/${portfolioId}?${queryParams}`);
  };

  generateBaseReport = (type: BaseReportType, params: IBaseReportParams) => {
    let queryParams = new URLSearchParams(JSON.parse(JSON.stringify(params)));
    if ([BaseReportType.INCOME_EX_DATE, BaseReportType.INCOME_PAY_DATE, BaseReportType.INCOME_TAX_YEAR].includes(type)) {
      const extractType = incomeExtractMap[type as BaseReportType.INCOME_EX_DATE | BaseReportType.INCOME_PAY_DATE | BaseReportType.INCOME_TAX_YEAR];

      queryParams = new URLSearchParams(JSON.parse(JSON.stringify({ ...params, extractType })));
      return this.http.get(`api/base-reports/income?${queryParams}`, { observe: 'response', responseType: 'blob' });
    }

    return this.http.get(`api/base-reports/${type}?${queryParams}`, { observe: 'response', responseType: 'blob' });
  };

  // Cost Change Report
  getAllCCSecurities = (portfolioId: number, body: CostChangesParams) => {
    return this.http.post<ISecurity[]>(`api/reports/${ReportCategory.COST_CHANGES}/securities/${portfolioId}`, body);
  };

  // Realised, CGT Schedule Report
  getAllRealisedSecurities = (portfolioId: number, body: RealisedParams) => {
    return this.http.post<ISecurity[]>(`api/reports/${ReportCategory.REALISED}/securities/${portfolioId}`, body);
  };

  // Unrealised Report
  getAllUnrealisedSecurities = (portfolioId: number, body: UnRealisedParams) => {
    return this.http.post<ISecurity[]>(`api/reports/${ReportCategory.UNREALISED}/securities/${portfolioId}`, body);
  };

  // Income Report
  getAllIncomeSecurities = (portfolioId: number, body: IncomeParams) => {
    return this.http.post<ISecurity[]>(`api/reports/${ReportCategory.INCOME}/securities/${portfolioId}`, body);
  };

  // Income Report
  getAllActivitySecurities = (portfolioId: number, body: IncomeParams) => {
    return this.http.post<ISecurity[]>(`api/reports/${ReportCategory.ACTIVITY}/securities/${portfolioId}`, body);
  };
}
