<p-fieldset [legend]="reportsForm.label" [toggleable]="true">
  <div class="filter-container">
    <div class="filters">
      <app-fy-calender label="Report Date Range" [isMandatory]="true" (onChange)="onChangeDates($event)" [style]="{ 'min-width': '16rem' }" />
      <div class="flex align-items-center gap-2">
        <span class="p-float-label security-wrapper" *ngIf="showSecurityFilter">
          <p-multiSelect
            class="security-filter"
            placeholder="Securities"
            [options]="securities"
            [(ngModel)]="securityIds"
            [filter]="true"
            filterBy="securityCode,exchangeCode,securityName"
            optionLabel="securityName"
            optionValue="securityId"
            [dropdownIcon]="isFetchingSecurities ? 'pi pi-spin pi-spinner' : ''"
            display="chip"
            [showClear]="true"
            [disabled]="!canGenerateReport"
            [resetFilterOnHide]="true"
          >
            <ng-template let-sec pTemplate="selectedItem">
              <span *ngIf="sec">
                <strong>{{ sec.securityCode }}.{{ sec.exchangeCode }}</strong> - {{ sec.securityName }}
              </span>
            </ng-template>
            <ng-template let-sec pTemplate="item">
              <span class="dropdown-items">
                <strong>{{ sec.securityCode }}.{{ sec.exchangeCode }}</strong> - {{ sec.securityName }}
              </span>
            </ng-template>
          </p-multiSelect>
          <label>Securities</label>
        </span>
      </div>
    </div>
    <p-button
      styleClass="p-button-outlined"
      label="Generate Report"
      (onClick)="onClickGenerateReport()"
      [loading]="reportsForm.isLoading"
      [disabled]="!canGenerateReport"
    ></p-button>
  </div>
</p-fieldset>
