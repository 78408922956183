<div class="p-fluid">
  <p>
    Security code: {{ data.securityCode }}<br />
    Security name: {{ data.securityName }}
  </p>

  <p-table
    [value]="data.corporateActions"
    id="activityId"
    styleClass="p-datatable-gridlines"
    [resizableColumns]="true"
    *ngIf="data.corporateActions.length > 0"
    [loading]="isLoadingActivity"
  >
    <ng-template pTemplate="header">
      <tr>
        <th pResizableColumn>Date</th>
        <th pResizableColumn>
          Activity
          <p-columnFilter field="activity" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
            <ng-template pTemplate="header">
              <div class="px-3 pt-3 pb-0">
                <span class="font-bold">Activity Filter</span>
              </div>
            </ng-template>
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect
                [ngModel]="value"
                [options]="activityTypes"
                placeholder="Any"
                (onChange)="filter($event.value)"
                optionLabel="label"
                optionValue="value"
                [overlayVisible]="true"
              >
                <ng-template let-option pTemplate="item">
                  <div class="inline-block vertical-align-middle">
                    <span class="ml-1 mt-1">{{ option.label }}</span>
                  </div>
                </ng-template>
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </th>
        <th pResizableColumn>Description</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-item>
      <tr [class.highlighted]="currentlySelectedActivityId === item.activityId">
        <td>{{ item.date }}</td>
        <td>
          <a (click)="openCoopActionDetailsModal(item)">{{ item.activity | startCase }}</a>
          <p-progressSpinner
            *ngIf="isLoadingActivity && item.activityId === selectedActivityId"
            class="loading-activity"
            styleClass="w-1rem h-1rem"
            strokeWidth="6"
            animationDuration=".5s"
          ></p-progressSpinner>
        </td>
        <td>
          <app-description-htmlrenderer [html]="item.description"></app-description-htmlrenderer>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <p class="p-text-secondary text-center" *ngIf="data.corporateActions.length === 0">Your search returned no corporate actions.</p>
</div>
