<!-- <div class="p-fluid">
  <span class="p-float-label">
    <input
      type="text"
      data-testid="loginInput"
      pInputText
      [(ngModel)]="inputText"
      (keyup.enter)="loginPageService.acceptToS ? onConfirm() : maybeNagAboutToS()"
      appAutoFocus
      autocomplete="username"
    />
    <label>Email</label>
  </span>
  <small class="p-invalid p-error" *ngIf="invalid || tosNag">{{ tosNag ? tosNagMessage : errorMessage }}</small>
</div>

<div class="p-fluid mt-3">
  <p-checkbox [inputId]="inputId" [binary]="true" [(ngModel)]="loginPageService.acceptToS" (onChange)="tosNag = false" />
  <label [for]="inputId">
    I agree to eSTM's
    <a href="https://estm-public-resources.s3.ap-southeast-2.amazonaws.com/eSTM+SaaS+Agreement+-+Online.pdf" target="_blank">Terms and Conditions</a>.
  </label>
</div>

<div (click)="maybeNagAboutToS()">
  <app-suspense-button
    label="Log In"
    [loading]="loading"
    (buttonClick)="onConfirm()"
    [disabled]="!loginPageService.acceptToS || !inputText?.trim()"
  ></app-suspense-button>
</div>
 -->

<div class="p-fluid">
  <div class="terms-section">
    <p-checkbox [inputId]="inputId" [binary]="true" [(ngModel)]="loginPageService.acceptToS" (change)="updateButtonStates()" size="large" />
    <label [for]="inputId">
      I agree to eSTM's
      <a href="https://estm-public-resources.s3.ap-southeast-2.amazonaws.com/eSTM+SaaS+Agreement+-+Online.pdf" target="_blank">Terms and Conditions</a
      >.
    </label>

    <!-- <label class="checkbox-label">
      <input type="checkbox" [(ngModel)]="loginPageService.acceptToS" (change)="updateButtonStates()" />
      I agree to eSTM's
      <a href="https://estm-public-resources.s3.ap-southeast-2.amazonaws.com/eSTM+SaaS+Agreement+-+Online.pdf" target="_blank">Terms and Conditions</a
      >.
    </label> -->
  </div>

  <div class="login-section mt-3">
    <span class="p-float-label">
      <input
        data-testid="loginInput"
        pInputText
        appAutoFocus
        autocomplete="username"
        type="email"
        [(ngModel)]="email"
        [disabled]="!this.loginPageService.acceptToS"
        (input)="updateButtonStates()"
      />
      <label>Email</label>
    </span>
    <small class="p-invalid p-error" *ngIf="invalid || tosNag">{{ tosNag ? tosNagMessage : errorMessage }}</small>

    <!-- <div class="email-input">
      <label>Email</label>
      <input type="email" [(ngModel)]="email" [disabled]="!this.loginPageService.acceptToS" (input)="updateButtonStates()" />
    </div> -->

    <!-- <button class="login-btn" [disabled]="!isLoginEnabled" (click)="onLogin()">eSTM Login</button> -->
    <app-suspense-button label="eSTM Login" (buttonClick)="onLogin()" [disabled]="!isLoginEnabled"></app-suspense-button>

    <div class="divider">
      <span>OR</span>
    </div>

    <app-suspense-button
      label="Corporate Login"
      (buttonClick)="onSSOLogin()"
      [disabled]="!this.loginPageService.acceptToS || isLoginEnabled"
    ></app-suspense-button>
  </div>
</div>
