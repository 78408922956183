<app-notice-card icon="pi pi-spin pi-spinner" content="Loading..." *ngIf="state === 'loading'"></app-notice-card>
<!-- <app-notice-card icon="pi pi-exclamation-triangle" [content]="errorText" *ngIf="state === 'error'"></app-notice-card> -->

<div class="p-card m-3 p-3" *ngIf="state !== 'loading'">
  <div class="inner-form grid">
    <h1 class="col-12 m-0 pb-0">{{ renderAccountDetails() }}</h1>
    <div class="col-12 m-0 pt-0"><i class="pi pi-envelope text-sm"></i> {{ account.userEmail }}</div>

    <div class="col-6">
      <h2 class="m-0 col-12">Access</h2>
      <div class="col-12">
        <label>
          <p-checkbox
            [readonly]="isCurrentLoggedInUser"
            [ngModel]="hasAccessInputValue"
            [binary]="true"
            (onChange)="onHasAccessChange($event)"
          ></p-checkbox>
          User Enabled <br />
          <small class="last-access-time"
            >Last access:
            <app-user-last-access-time-loader [userId]="userId"></app-user-last-access-time-loader>
          </small>
        </label>
      </div>

      <div class="col-12" *ngIf="canBeAdmin && isSiteAdminUser">
        <label>
          <p-checkbox [(ngModel)]="isAdminInputValue" [binary]="true" (onChange)="onIsAdminChange()"></p-checkbox>
          Administrator
        </label>
      </div>
      <div class="col-12" *ngIf="isSiteAdminUser">
        <label>
          <p-checkbox [(ngModel)]="isSSO" [binary]="true" (onChange)="onEnabledSSOChange()"></p-checkbox>
          SSO Enabled
        </label>
      </div>
    </div>

    <div class="col-6">
      <h2 class="m-0 col-12">Role</h2>
      <!-- optionValue="apiInteger" -->
      <div class="col-12">
        <p-dropdown [options]="roles" optionLabel="displayName" [(ngModel)]="roleInputValue" (onChange)="onRoleChange()" class="p-fluid"></p-dropdown>
      </div>
      <div class="col-12">
        <p-button *ngIf="isSiteAdminUser" [disabled]="!account.isMFAEnabled" label="Disable User MFA" (onClick)="onDisableMfa()"></p-button>
      </div>
    </div>
  </div>
</div>
