// API interface for the user-admin-page
import { UserAdminPageAccountDisplayData, UserAdminPageComponent } from './user-admin-page.component';
import { Roles } from '../../../-services-/auth-framework/api-constants/roles/roles';
import { ApiHttpRequest } from '../../../../api-connector/api-http-request';

export class UserAdminPageLoader {
  constructor(private subject: UserAdminPageComponent) {}

  private parseUserRolesIntoString(rawResponseRoles: number[]): string {
    const displayNames = rawResponseRoles.map((roleId) => Roles.byApiInteger(roleId).displayName);
    return displayNames.join(', ');
  }

  async getUserList(): Promise<UserAdminPageAccountDisplayData[]> {
    // make API request
    const response = await ApiHttpRequest.listUsersWithLatestLoginActivities();

    return response.map((item) => ({
      userId: item.id,
      email: item.userEmail,
      isBlocked: item.isBlocked,
      isDisabled: item.isDisabled,
      role: this.parseUserRolesIntoString(item.userRoles),
      username: `${item.firstName} ${item.lastName}`,
      lastLoginTimeISO8601: item.lastLoginDateTime,
      isMFAEnforced: item.isMFAEnforced,
      isMFAEnabled: item.isMFAEnabled,
      isSSO: item.isSSO
    }));
  }
}
