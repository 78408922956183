import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { MenuItem } from 'primeng/api';

import { reportMenuItems } from '../report-menu-types';
import { ReportCategory, ReportMenuEvent, ReportSubCategory } from '../reports.types';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'report-menu',
  templateUrl: './report-menu.component.html',
  styleUrls: ['./report-menu.component.css']
})
export class ReportMenuComponent implements OnInit, OnChanges {
  @Input({ required: true }) category!: ReportCategory;
  @Input({ required: true }) subCategory!: ReportSubCategory;

  @Output() onMenuClick = new EventEmitter<ReportMenuEvent>();

  items: MenuItem[] = [];

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    this.getMenuItems();
  }

  get isProdEnv() {
    const deployedEnv = environment.envVar.DEPLOYED_ENV_NAME.toLowerCase();
    // console.log(deployedEnv);
    return deployedEnv === 'production';
  }

  // Fn to create Menu Items required as per component
  private getMenuItems = () => {
    this.items = reportMenuItems.map((report) => {
      const obj: MenuItem = {};
      obj.id = report.category;
      obj.label = report.label;
      obj.items = report.items.map(({ label, category, subCategory, hideInProd = false }) => {
        const itemObj: MenuItem = {
          id: `${category}_${subCategory}`,
          label,
          routerLink: `${category}/${subCategory}`,
          queryParamsHandling: 'preserve',
          visible: this.isProdEnv ? !hideInProd : true
        };
        itemObj.command = (e) => {
          this.onMenuClick.emit({ category, subCategory });
        };
        itemObj.styleClass = this.isActive(itemObj) ? 'active-report' : '';
        return itemObj;
      });
      obj.expanded = this.isExpanded(obj);
      return obj;
    });
  };

  private isExpanded = (item: MenuItem) => item.id === this.category;
  private isActive = (item: MenuItem) => item.id === `${this.category}_${this.subCategory}`;
}
