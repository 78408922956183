import { AuthModule } from 'angular-auth-oidc-client';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    AuthModule.forRoot({
      config: {
        authority: 'https://cognito-idp.ap-southeast-2.amazonaws.com/ap-southeast-2_a3EffpBAa',
        redirectUrl: 'http://localhost:4200/callback',
        clientId: '1a4uq2c28hgdsoj1c8ieima3d2',
        scope: 'email openid',
        responseType: 'code'
      },
    }),
  ],
  exports: [AuthModule],
})
export class AuthConfigModule {}
