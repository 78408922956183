import { Component, OnInit } from '@angular/core';
import { ReportsFormService } from '../../reports-form.service';
import { ICalenderEvent } from 'src/app/common/calender/calender.types';
import { ISecurity, TransactionActivityParams } from '../../reports.types';
import { Subscription } from 'rxjs';
import { ReportsService } from '../../reports.service';

@Component({
  selector: 'activity-report',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})
export class ActivityComponent implements OnInit {
  // Filters
  fromDate = '';
  toDate = '';

  showSecurityFilter = true;

  isFetchingSecurities = false;
  securities: ISecurity[] = [];

  securityIds: string[] = [];

  subPortfolioSubcriber!: Subscription;

  constructor(private reportsFormService: ReportsFormService, private reportService: ReportsService) {
    this.subPortfolioSubcriber = this.reportsFormService.getSubPortfolioValue().subscribe((spf) => {
      spf && this.showSecurityFilter && this.fetchAllSecurities(spf.portfolioId, spf.id);
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subPortfolioSubcriber.unsubscribe();
  }

  get reportsForm() {
    return this.reportsFormService;
  }

  get canGenerateReport() {
    return !this.reportsForm.isLoading && this.reportsForm.portfolio?.id && this.fromDate && this.toDate;
  }

  fetchAllSecurities = (pfId: number, spfId?: number) => {
    if (this.showSecurityFilter && this.canGenerateReport) {
      this.isFetchingSecurities = true;
      this.securityIds = [];
      const body = {
        startDate: this.fromDate,
        endDate: this.toDate,
        subPortfolioId: spfId
      } as TransactionActivityParams;
      this.reportService.getAllIncomeSecurities(pfId, body).subscribe({
        next: (res) => {
          this.isFetchingSecurities = false;
          this.securities = res;
        },
        error: (err) => {
          this.isFetchingSecurities = false;
          this.securities = [];
        }
      });
    }
  };

  onChangeDates = ({ fromDate, toDate }: ICalenderEvent) => {
    this.fromDate = fromDate;
    this.toDate = toDate;
    this.fetchAllSecurities(this.reportsForm.portfolio?.id, this.reportsForm.subPortfolioId);
  };

  onClickGenerateReport = () => {
    const portfolioId = this.reportsForm.portfolio?.id;
    const url = `api/reports/activity/transaction-report/${portfolioId}`;
    const body = {
      startDate: this.fromDate,
      endDate: this.toDate,
      subPortfolioId: this.reportsForm.subPortfolioId,
      securityIds: this.securityIds
    } as TransactionActivityParams;
    this.reportsFormService.generateReport(url, body);
  };
}
