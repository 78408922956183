import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Role } from '../../../-services-/auth-framework/api-constants/roles/role';
import {
  AdministratorRole,
  AdvisorRole,
  BackofficeAdminRole,
  BackofficeRole,
  CompanyManagerRole,
  SiteAdminRole,
  eSupportRole
} from '../../../-services-/auth-framework/api-constants/roles/role-instances';
import { UserRolesApiInterface } from './loader';
import { extractErrorText } from '../../../utilities/extract-error-text';
import { AuthProviderService } from 'src/app/-services-/auth-framework/auth-provider.service';
import { MessageService } from 'primeng/api';
import { CheckboxChangeEvent } from 'primeng/checkbox';

export interface URPAccountData {
  name: string;
  id: number;
  hasAccess: boolean;
  role: Role;
  isAdministrator: boolean;
  isMFAEnforced: boolean;
  isMFAEnabled: boolean;
  isSSO: boolean;
  userEmail: string;
}

@Component({
  selector: 'app-user-roles-page',
  templateUrl: './user-roles-page.component.html',
  styleUrls: ['./user-roles-page.component.css']
})
export class UserRolesPageComponent implements OnInit {
  // internal states
  state: 'loading' | 'normal' | 'error' = 'loading';
  errorText = '';

  constructor(private activatedRoute: ActivatedRoute, private authService: AuthProviderService, private msgService: MessageService) {}

  // basic info
  account!: URPAccountData;
  roles: Role[] = [];
  userId = this.activatedRoute.snapshot.params.id;

  // internal states
  hasAccessInputValue = false;
  roleInputValue: Role = eSupportRole;
  isAdminInputValue = false;
  isMFAEnforced = false;
  isSSO = false;
  isCurrentLoggedInUser = false;
  disableMfa = false;

  // utilities
  private loader = new UserRolesApiInterface(this.activatedRoute.snapshot.params.id);

  // Read from this.account and re-calculate the internal states
  // should only be called once this.account is loaded
  private applyAccountValueToInputs(): void {
    if (this.state !== 'normal') return;

    this.hasAccessInputValue = this.account.hasAccess;
    this.roleInputValue = this.account.role;
    this.isAdminInputValue = this.account.isAdministrator;
    this.isMFAEnforced = this.account.isMFAEnforced;
    this.isSSO = this.account.isSSO;
  }

  async ngOnInit(): Promise<void> {
    await this.preFetchAccount();
  }

  preFetchAccount = async () => {
    this.state = 'loading';
    try {
      this.account = await this.loader.loadAccount();
      this.state = 'normal';
      this.applyAccountValueToInputs();
    } catch (e: any) {
      this.state = 'error';
      this.errorText = extractErrorText(e);
      this.showError(this.errorText);
    }
    this.isCurrentLoggedInUser = Number(this.authService.account?.id) === Number(this.account.id);
    this.getRoles();
  };

  get isEsupportUser() {
    return [eSupportRole].some((role) => this.authService.verifyUserRole(role));
  }

  get isSiteAdminUser() {
    return [SiteAdminRole].some((role) => this.authService.verifyUserRole(role));
  }

  get isAdministrator() {
    return [AdministratorRole].some((role) => this.authService.verifyUserRole(role));
  }

  get canBeAdmin() {
    return Number(this.roleInputValue?.id) === CompanyManagerRole.id;
  }

  getRoles = () => {
    if (this.isCurrentLoggedInUser) {
      this.roles = [this.account.role];
    } else if (this.isEsupportUser) {
      this.roles = [SiteAdminRole];
    } else if (this.isSiteAdminUser || this.isAdministrator) {
      this.roles = [CompanyManagerRole, BackofficeAdminRole, BackofficeRole, AdvisorRole];
    }
  };

  // various change listeners
  async onHasAccessChange(event: CheckboxChangeEvent): Promise<void> {
    try {
      await this._quokkaHandlePromise(this.loader.changeHasAccess(event.checked));
      this.hasAccessInputValue = event.checked;
    } catch (e) {
      // TODO refactor
      // already handled in previous item call stack
      //console.error(e);
    }
  }

  async onIsAdminChange(): Promise<void> {
    this._quokkaHandlePromise(this.loader.changeIsAdmin(this.account.role, this.isAdminInputValue));
  }

  async onEnforceMFAChange(): Promise<void> {
    this._quokkaHandlePromise(this.loader.changeEnforceMFA(this.isMFAEnforced));
  }
  async onEnabledSSOChange(): Promise<void> {
    this._quokkaHandlePromise(this.loader.changeUserSSO(this.isSSO));
  }

  async onDisableMfa(): Promise<void> {
    this._quokkaHandlePromise(this.loader.disableMfaForUser(this.account.id));
  }

  async onRoleChange(): Promise<void> {
    if (!this.canBeAdmin) {
      this.isAdminInputValue = false;
    }
    this._quokkaHandlePromise(this.loader.changeRole(this.isAdminInputValue, this.roleInputValue));
  }

  private _quokkaHandlePromise(promise: Promise<any>): Promise<void> {
    this.state = 'loading';
    return promise
      .then(() => {
        this.preFetchAccount();
        this.state = 'normal';
        this.msgService.add({ severity: 'success', summary: 'Success', detail: 'Your change has been saved' });
        // return SwalToastSuccess.fire({ title: 'Your change has been saved' });
      })
      .catch((e: any) => {
        this.state = 'error';
        this.errorText = extractErrorText(e);
        this.showError(this.errorText);
        throw e;
      });
  }

  renderAccountDetails() {
    if (this.account.name && this.account.name.trim().length > 0) {
      return `${this.account.name}'s Details`;
    } else {
      return `Account Details`;
    }
  }

  showError = (errorMsg: string) => {
    this.msgService.add({ severity: 'error', summary: 'Error', detail: errorMsg });
  };
}
