import { startCase } from 'lodash-es';
import { IMessage } from './header.component';
import dayjs from 'dayjs';
import { APP_DATE_TIME_FORMAT } from 'src/app/app.constants';

// This function is no-longer used, keeping just in case of future scope of change in behaviour of reports display
const downloadMyFile = ({ downloadLink = '', portfolioCode = '', reportType = '' }) => {
  const date = dayjs().format(APP_DATE_TIME_FORMAT);
  const fileName = `${portfolioCode}_${reportType}_${date}.pdf`;
  if (downloadLink) {
    // console.log(fileName);
    const link = document.createElement('a');
    link.setAttribute('download', fileName);
    link.setAttribute('target', '_blank');
    link.setAttribute('href', downloadLink);
    // document.body.appendChild(link);
    link.click();
    link.remove();
  }
};

export const getNotificationDetails = ({ type, content }: IMessage) => {
  const status = content?.['status']?.toUpperCase() || 'NOT STARTED';
  const category = `${type}_${status}`;

  let notificationObj = {},
    showNotification = false;

  switch (category) {
    case 'REPORT_DONE':
      {
        showNotification = true;
        const reportType = startCase(content?.subCategory);
        notificationObj = {
          severity: 'success',
          summary: `${reportType} report has been generated successfully`,
          detail: `Please check notification for details`
        };
        // if (content) downloadMyFile(content);
      }
      break;
    case 'REPORT_FAILED':
      {
        showNotification = true;
        const reportType = startCase(content?.subCategory);
        const errorMsg: string = content?.message || '';
        const isNoData = errorMsg.replace(/\s/g, '').toLowerCase().includes('nodata');
        notificationObj = {
          severity: isNoData ? 'info' : 'error',
          summary: `Failed to generate ${reportType} report`,
          detail: errorMsg || `Please try again later`
        };
      }
      break;

    case 'CONTRACT_NOTES_DONE':
      {
        showNotification = true;
        notificationObj = {
          severity: content?.severity,
          summary: content?.summary,
          detail: content?.detail
        };
      }
      break;

    case 'IMPORT_FILE_SUCCESS':
      {
        const fileName = content?.fileName;
        showNotification = true;
        notificationObj = {
          severity: 'success',
          detail: `${fileName} imported successfully`
        };
      }
      break;
    case 'IMPORT_FILE_ERROR':
      {
        const fileName = content?.fileName;
        showNotification = true;
        notificationObj = {
          severity: 'error',
          detail: `Failed to import ${fileName}`
        };
      }
      break;

    case 'PORTFOLIO_PROCESSING_COMPLETED':
      {
        showNotification = true;
        notificationObj = {
          severity: 'success',
          summary: `Portfolio processing completed for '${content?.portfolioName}' (${content?.portfolioCode})`,
          detail: `Please check activities tab for details`
        };
      }
      break;
    case 'PORTFOLIO_PROCESSING_FAILED':
      {
        showNotification = true;
        const errorMsg: string = content?.message || '';
        notificationObj = {
          severity: 'error',
          summary: `Failed to process portfolio '${content?.portfolioName}' (${content?.portfolioCode})`,
          detail: errorMsg || `Please try again later`
        };
      }
      break;

    case 'RELEASE_NOTES_DONE':
      {
        showNotification = true;
        notificationObj = {
          severity: 'success',
          summary: `Release notes generated successfully`,
          detail: `Please check notification for link`
        };
      }
      break;
    case 'RELEASE_NOTES_FAILED':
      {
        showNotification = true;
        notificationObj = {
          severity: 'error',
          summary: `Failed to generate release notes`,
          detail: `Please try again later`
        };
      }
      break;

    default:
      break;
  }

  return { showNotification, notificationObj: { ...notificationObj, closable: true }, url: content?.downloadLink };
};
