import { Component, OnInit } from '@angular/core';
import { ReportsFormService } from '../../reports-form.service';
import { ICalenderEvent } from 'src/app/common/calender/calender.types';
import { CgtScheduleReport, ISecurity, RealisedParams, RealisedReport, ReportCategory } from '../../reports.types';
import { Subscription } from 'rxjs';
import { ReportsService } from '../../reports.service';

@Component({
  selector: 'realised',
  templateUrl: './realised.component.html',
  styleUrls: ['./realised.component.css']
})
export class RealisedComponent implements OnInit {
  // Filters
  fromDate = '';
  toDate = '';

  showSummary = true;
  dontShowCapitalGains = true;
  dontShowTransferOrIntraFundGain = true;

  isFetchingSecurities = false;
  securities: ISecurity[] = [];

  securityIds: string[] = [];

  subPortfolioSubcriber!: Subscription;

  constructor(private reportsFormService: ReportsFormService, private reportService: ReportsService) {
    this.subPortfolioSubcriber = this.reportsFormService.getSubPortfolioValue().subscribe((spf) => {
      spf && this.showSecurityFilter && this.fetchAllSecurities(spf.portfolioId, spf.id);
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subPortfolioSubcriber.unsubscribe();
  }

  get reportsForm() {
    return this.reportsFormService;
  }

  get showSummaryOption() {
    const cat = this.reportsForm.category;
    const subCat = this.reportsForm.subCategory as RealisedReport;
    const isBaseReport = cat === ReportCategory.BASE_REPORT;
    return !isBaseReport && [RealisedReport.SUMMARY, RealisedReport.NET_OFF_SUMMARY, RealisedReport.COST_RECON_SUMMARY].includes(subCat);
  }

  get canGenerateReport() {
    return Boolean(!this.reportsForm.isLoading && this.reportsForm.portfolio?.id && this.fromDate && this.toDate);
  }

  get showSecurityFilter() {
    const pathname = location.pathname;
    const allowedReports = [
      RealisedReport.DETAIL,
      RealisedReport.NET_OFF_DETAIL,
      RealisedReport.COST_RECON_DETAIL,
      CgtScheduleReport.CGT_SCHEDULE_DETAIL
    ];
    const canView = Boolean(allowedReports.find((rt) => pathname.endsWith(rt)));
    return canView;
  }

  get hideDontShowSecondOption() {
    const subCat = this.reportsForm.subCategory as CgtScheduleReport;
    return subCat === CgtScheduleReport.CGT_SCHEDULE_FORM;
  }

  fetchAllSecurities = (pfId: number, spfId?: number) => {
    if (this.showSecurityFilter && this.canGenerateReport) {
      this.isFetchingSecurities = true;
      this.securityIds = [];
      const body = {
        startDate: this.fromDate,
        endDate: this.toDate,
        subPortfolioId: spfId
      } as RealisedParams;
      this.reportService.getAllRealisedSecurities(pfId, body).subscribe({
        next: (res) => {
          this.isFetchingSecurities = false;
          this.securities = res;
        },
        error: (err) => {
          this.isFetchingSecurities = false;
          this.securities = [];
        }
      });
    }
  };

  onChangeDates = ({ fromDate, toDate }: ICalenderEvent) => {
    this.fromDate = fromDate;
    this.toDate = toDate;
    this.fetchAllSecurities(this.reportsForm.portfolio?.id, this.reportsForm.subPortfolioId);
  };

  onClickGenerateReport = () => {
    const portfolioId = this.reportsForm.portfolio?.id;
    const category = this.reportsForm.category;
    const subCategory = this.reportsForm.subCategory;
    const isBaseReport = category === ReportCategory.BASE_REPORT;
    const url = isBaseReport ? `api/${category}/${subCategory}/${portfolioId}` : `api/reports/${category}/${subCategory}/${portfolioId}`;
    const body = {
      showSummary: this.showSummary,
      dontShowCapitalGains: this.dontShowCapitalGains,
      dontShowTransferOrIntraFundGain: this.dontShowTransferOrIntraFundGain,
      subPortfolioId: this.reportsForm.subPortfolioId,
      startDate: this.fromDate,
      endDate: this.toDate,
      securityIds: this.securityIds
    } as RealisedParams;
    this.reportsFormService.generateFinalReport(url, body, isBaseReport);
  };
}
