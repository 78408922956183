<div class="container">
  <form class="filter-container" [formGroup]="formGroup">
    <div class="p-fluid security-wrapper">
      <div class="full-width-security">
        <app-security-reference-search
          [isMandatory]="true"
          [includeAllSecurities]="true"
          label="Security Code"
          formControlName="securityId"
        ></app-security-reference-search>
      </div>
    </div>

    <div class="p-fluid flex full-space">
      <app-fy-calender
        label="Date Range"
        [isMandatory]="true"
        [initialValue]="dateRange"
        [dropdown]="{ includeAll: true, isAllFY: false }"
        (onChange)="onChangeDates($event)"
        [style]="{ width: '16rem' }"
      />
    </div>
    <!-- <span class="p-float-label">
      <p-dropdown formControlName="financialYear" [options]="taxYears" optionValue="value" optionLabel="label"></p-dropdown>
      <label for="financialYear">Financial Year</label>
    </span> -->
    <!-- </div> -->

    <!-- <div class="lg:col-3 sm:col-6 md:col-1"></div> -->

    <div class="btn-container">
      <span class="p-buttonset flex">
        <button
          pButton
          class="p-button-outlined p-button-primary p-fluid"
          label="Confirm"
          (click)="onConfirm()"
          icon="pi pi-check-circle"
          [disabled]="!isFormValid"
        ></button>
        <button
          pButton
          class="p-button-outlined p-button-danger"
          label="Reset"
          (click)="onReset()"
          icon="pi pi-refresh"
          [disabled]="isFormDirty"
        ></button>
      </span>
    </div>
  </form>
</div>
