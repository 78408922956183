import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAdminPageLoader } from './loader';
import { UapFilterService } from './uap-filter.service';
import { extractErrorText } from '../../../utilities/extract-error-text';
import { AdministratorRole, CompanyManagerRole, SiteAdminRole } from 'src/app/-services-/auth-framework/api-constants/roles/role-instances';
import { AuthProviderService } from 'src/app/-services-/auth-framework/auth-provider.service';
import { TableRowSelectEvent } from 'primeng/table';
import { MenuItem, MessageService } from 'primeng/api';
import { HttpClient } from '@angular/common/http';

export interface UserAdminPageAccountDisplayData {
  username: string;
  email: string;
  isBlocked: boolean;
  isDisabled: boolean;
  role: string;
  userId: number;
  lastLoginTimeISO8601: string;
  isMFAEnabled: boolean;
  isMFAEnforced: boolean;
  isSSO: boolean;
}

@Component({
  selector: 'app-user-admin-page',
  templateUrl: './user-admin-page.component.html',
  styleUrls: ['./user-admin-page.component.css'],
  providers: [UapFilterService]
})
export class UserAdminPageComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private router: Router,
    private currentRoute: ActivatedRoute,
    private filterService: UapFilterService,
    private authService: AuthProviderService,
    private msgService: MessageService
  ) {}

  isLoading = true;
  hasError = false;
  errorText = '';

  private loader = new UserAdminPageLoader(this);
  accounts: UserAdminPageAccountDisplayData[] = [];
  displayedAccounts: UserAdminPageAccountDisplayData[] = [];

  // Re-calculate which accounts to display based on the states of the filters
  refreshFilters(): void {
    this.displayedAccounts = this.accounts.filter((acc) => this.filterService.evaluate(acc));
  }

  async loadAccounts(): Promise<void> {
    this.accounts = await this.loader.getUserList();
    this.isLoading = false;
    this.refreshFilters();
  }

  ngOnInit(): void {
    this.loadAccounts().catch((e) => {
      this.hasError = true;
      this.errorText =
        'The information is currently unavailable, and eSTM Support has been notified and working on the issue. If the problem persists, then please contact support@estm.com.au';
      console.log('User Admin Page Error: ' + extractErrorText(e));
    });
  }

  get hasWriteAccess() {
    return [SiteAdminRole, AdministratorRole].some((role) => this.authService.verifyUserRole(role));
  }

  // get canViewMapping() {
  //   return [SiteAdminRole, AdministratorRole].some((role) => this.authService.verifyUserRole(role));
  // }

  getMenuOptions = (user: UserAdminPageAccountDisplayData) => {
    return [
      {
        label: 'Update User Details',
        icon: 'pi pi-pencil',
        visible: this.hasWriteAccess,
        routerLink: `roles/${user.userId}`
      },
      {
        label: 'Disable User MFA',
        icon: 'pi pi-ban',
        visible: this.hasWriteAccess,
        disabled: !user.isMFAEnabled,
        command: () => this.disableMfaForUser(user.userId)
      },
      {
        label: 'View User Group Mapping',
        icon: 'pi pi-arrow-right',
        visible: this.hasWriteAccess,
        routerLink: `${user.userId}/user-groups`
      }
    ] as MenuItem[];
  };

  goToUserRoles(user: UserAdminPageAccountDisplayData): void {
    const { userId } = user;
    this.router.navigate(['roles', userId], { relativeTo: this.currentRoute });
  }

  renderUserName(username: string) {
    if (username && username.trim().length > 0) {
      return username;
    } else {
      return 'N/A';
    }
  }

  async disableMfaForUser(userId: number) {
    this.isLoading = true;
    this.http.put(`api/mfa/disableUserMfa`, { userId }).subscribe({
      next: (res) => {
        // this.isLoading = false;
        this.loadAccounts();
      },
      error: (err) => {
        this.isLoading = false;
        this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Failed to disable user MFA' });
      }
    });
  }
}
