import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CorporateActionsData } from '../../-models-/corporate-action-data.model';
import { CoopActionDetailsService } from '../../coop-action-details.service';
import { CorporateAction } from 'src/api-connector/api-collections/cgt-view/cgt-view.types';

@Component({
  selector: 'app-corporate-actions-tab',
  templateUrl: './corporate-actions-tab.component.html',
  styleUrls: ['./corporate-actions-tab.component.css']
})
export class CorporateActionsTabComponent implements OnInit, OnChanges {
  @Input() data!: CorporateActionsData;

  // internal state
  currentlySelectedActivityId: string | null = null;
  activityTypes: { label: string; value: string }[] = [];

  constructor(private detailsService: CoopActionDetailsService) {}

  ngOnInit(): void {}

  ngOnChanges({ data }: SimpleChanges): void {
    // re-list all activity types
    this.listActivityTypes(data?.currentValue?.corporateActions || []);
  }

  get isLoadingActivity() {
    return this.detailsService.isLoading;
  }

  get selectedActivityId() {
    return this.detailsService.selectedActivityId;
  }

  listActivityTypes = (data: CorporateAction[]) => {
    const types: any = {};
    data.forEach((item) => {
      if (!types[item.activity]) {
        types[item.activity] = { label: item.activity, value: item.activity };
      }
    });
    this.activityTypes = Object.values(types);
  };

  openCoopActionDetailsModal(item: CorporateAction) {
    this.currentlySelectedActivityId = item.activityId;
    this.detailsService.requestCoopActionDetailsModal(item);
  }
}
