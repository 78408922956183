// Single-responsibility API interface

import { URPAccountData } from './user-roles-page.component';
import { LegacyDirectApiRequest } from '../../../-services-/auth-framework/api-connect/legacy-direct-api-request';
import { AdministratorRole } from '../../../-services-/auth-framework/api-constants/roles/role-instances';
import { Roles } from '../../../-services-/auth-framework/api-constants/roles/roles';
import { Role } from '../../../-services-/auth-framework/api-constants/roles/role';

export class UserRolesApiInterface {
  constructor(private userId: string) {}

  async loadAccount(): Promise<URPAccountData> {
    const response = await this.getUserFromApi();

    // extract all roles
    const roles = response.userUserRoleMapping.map((item: { roleId: number }) => Roles.byApiInteger(item.roleId));

    // piece together what the UI needs
    return {
      role: roles.filter((item: Role) => item !== AdministratorRole)[0],
      id: response.id,
      hasAccess: !response.isDisabled,
      isMFAEnforced: response.isMFAEnforced,
      isMFAEnabled: response.isMFAEnabled,
      isSSO: response.isSSO,
      name: `${response.firstName} ${response.lastName}`,
      isAdministrator: roles.some((role: Role) => role === AdministratorRole),
      userEmail: response.userEmail
    };
  }

  async changeIsAdmin(oldRole: Role, newIsAdmin: boolean): Promise<void> {
    // calculate new user role ids
    const newUserRoleIds: number[] = [oldRole.apiInteger];
    if (newIsAdmin) newUserRoleIds.push(AdministratorRole.apiInteger);

    await LegacyDirectApiRequest.put('/api/users/updateRole', {
      userId: this.userId,
      newUserRoleIds
    });
  }

  async changeEnforceMFA(isMFAEnforced: boolean): Promise<void> {
    await LegacyDirectApiRequest.put('/api/users/enforceMFA', {
      userId: +this.userId,
      isMFAEnforced
    });
  }
  async changeUserSSO(isSSOEnabled: boolean): Promise<void> {
    await LegacyDirectApiRequest.put('/api/users/updateSsoStatus', {
      userId: +this.userId,
      isSSOEnabled
    });
  }

  async disableMfaForUser(userId: number): Promise<void> {
    await LegacyDirectApiRequest.put('/api/mfa/disableUserMfa', { userId });
  }

  // Change role.
  // WARNING - this will remove all roles of the account,
  // except for administrator
  async changeRole(oldIsAdmin: boolean, newRole: Role): Promise<void> {
    // calculate new user role ids
    const newUserRoleIds: number[] = [newRole.apiInteger];
    if (oldIsAdmin) newUserRoleIds.push(AdministratorRole.apiInteger);

    await LegacyDirectApiRequest.put('/api/users/updateRole', {
      userId: this.userId,
      newUserRoleIds
    });
  }

  async changeHasAccess(newHasAccess: boolean): Promise<void> {
    return await LegacyDirectApiRequest.put('/api/users/disableUser', {
      userId: +this.userId,
      isDisabled: !newHasAccess
    });
  }

  private getUserFromApi() {
    return LegacyDirectApiRequest.get(`/api/users/byId/${this.userId}`);
  }
}
